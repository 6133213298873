.App {
  text-align: center;
}
h1 {
  color: gray;
}
h2 {
  font-size: .8em;
}
.PropName {
  font-weight: bold;
  color: #6264A7;
}
.Logo {
  font-size: 45pt;
  color: #6264A7;
}
.Error {
  color: red;
}
pre {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}